@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');

.App-header {
  background-color: #1e1e1e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.textbox {
  animation-duration: 2s;
  animation-name: show;
}

.shortabout {
  animation-duration: 1.5s;
  animation-name: show;
}

.hello {
  font-size: 1.2em;
  animation-duration: 1.5s;
  animation-name: show;
}

.profpic {
  width: 200px;
  height: auto;
  border-radius: 50%;
  animation-duration: 2s;
  animation-name: show;
  outline: 4px solid #ccffff;
  border: 6px solid #1f2335;
}

h3 {
  text-decoration: underline;
  text-decoration-color: #ccffff;
  text-underline-offset: 6px;
  text-decoration-thickness: 3px;
  animation-duration: 0.5s;
  animation-name: right;
}

a {
  color: #ccffff;
  text-decoration: none;
  line-height: 1.5;
}

.neonline {
  color: #ccffff;
  background-color: #ccffff;
  border-color : #ccffff;
  height: 5;
  box-shadow: 0px 0px 16px #ccffff;
  animation-duration: 1s;
  animation-name: width;
}